.dx-datagrid .dx-row > td{
    padding-top: 0px ;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 14px;
    line-height: 20px;
}

.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }