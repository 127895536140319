@import "../../../themes/generated/variables.base.scss";

$color-texto-label: #4a4a50;

.titleCard{
  font-size: 23px;
  float: left;
  display: inline-block;
  padding-top: 3px;
  .titleCard-element{
    width: 100%;
  }
}

.titleCardInfo{
  font-size: 23px;
  padding-top: 5px;
  display: inline-block;
  width:50%;
  .titleCard-element{
    
    width: 100%;
  }
  .titleCard-element-container{
    width: 30%;
    display: inline-block;
    .titleCard-element-container-title{
      font-weight: bold;
      font-size: 21px;
      text-align: center;
    }
    .titleCard-element-container-content{
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}


.header_sep{
  margin-top: 5px ;
  margin-left: 7px;
}

.separacion-custom{
  
}
.separacion{
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.separacion-bottom{
  padding: 5px;
  float: left;
}
.separacion-izquierda{
  padding-left: 35px;
}
.texto-largo{
  width:100%;
  height:70%;
}
.label{
  margin-left: 25px;
  color: $color-texto-label;
}

.label-rubro{
  font-size: 20px;
  margin-left: 55px;
  margin-bottom: 5px;
  color: $color-texto-label;
}
.area-combos{
  display:-webkit-flex;
  display:flex;
}
.area-combos>div{
  width: 35%;
  padding: 10px;
}

.item-lista{
  margin: 1px 1px 1px 1px;
  padding-right: 25px;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  -webkit-justify-content:space-between;
  justify-content:space-between;
}

.no-aplica{
  background-color: rgb(240, 205, 205) !important;
}

.encabezado{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:space-between;
  justify-content:space-between;
}
.contenido{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:space-between;
  justify-content:space-between;
  flex-grow:1;

}
.contenido-izquierda{
  width:20%;
}
.contenido-derecha{
  width:80%;
}
.pie{
  float: left;
  display:-webkit-flex;
  display:flex;
}

.boton {  
  background-color: rgb(9, 140, 216);  
  color: white;
  float: right;
  &:hover{
    background-color: rgb(6, 106, 163)!important;  
  }
}  

.button-no-aplica{
  background-color: red;  
  color: white;
  &:hover{
    background-color: #B10303 !important;  
  }
}

.button-style{
  width: 125px;
  background-color: red;  
  color: white;
  float: right;
  &:hover{
    background-color: #B10303 !important;  
  }
}

.button-style-apply{
  width: 125px;
  background-color: #009688;
  color: white;
  &:hover{
    background-color: #02766C;
  }
}

.button-dowload-file{
  float: right;
  background-color: #009688;
  color: white;
  &:hover{
    background-color: #02766C;
  }
}

.circle-title{
  color: whitesmoke;
  float: left;
  background: #646463;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-top: 1px;
  text-align: center;
  //padding-left: 10px;
  font-weight: bold;
  margin-right: 13px;
  padding-top: 5px;
}

.status-cards{
  padding-top: 8px;
  padding-bottom: 8px;
  float: right;
  display: grid;
  .one{
    grid-column: 1/2;
    padding-bottom: 2px;
  }
  .two{
    padding-top: 5px;
    grid-column: 1/2;
    align-content: center;
  }
}

