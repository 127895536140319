.coment {
    padding-bottom: 2px;
    border-radius: 15px;
    .row{
      padding: 0;
      margin: 0 ;
      clear: both;
      white-space: break-word;
      .comment{
        color:#C1DBD6;
        padding-left: 10px;
        margin-bottom: 0px;
        position: relative;
        top: -10px;
      }
      .name{
        min-height: 35px;
        margin-bottom: 100px;
        color:white;
      }
      .date{
        font-size: 12px;
        color: gray;
     }
     p{
      text-align: left;
      white-space: break-spaces;
      }
      .icon{
        width: 20px;
        height: 20px;
        border-radius: 20px;
        float: left;
        margin-bottom: 5px;
     }
     .user_name{
        font-weight: bold;
        padding-left: 10px;
        float: left;
        margin: 0 ;
        font-size: 14px ;
        color:#C1DBD6;
        position: relative;
        top: -10px;
        
     }
    }
  }

.comentresponse {
  padding-bottom: 2px;
  border-radius: 15px;
  .row{
    padding: 0;
    margin: 0 ;
    clear: both;
    white-space: break-word;
    .comment{
      color :#C1DBD6;
      padding-left: 10px;
      margin-bottom: 0px;
      position: relative;
      top: -10px;
    }
    .name{
      min-height: 35px;
      margin-bottom: 100px;
      color:rgb(90, 88, 88);
    }
    .date{
      font-size: 12px;
      color: gray;
    }
    p{
    margin-left: auto;
    white-space: break-spaces;
    }
    .icon{
      width: 20px;
      height: 20px;
      border-radius: 20px;
      margin-left: auto;
      margin-bottom: 5px;
    }
    .user_name{
      font-weight: bold;
      padding-left: 10px;
      margin-left: auto;
      margin: 0 ;
      font-size: 14px ;
      color:#C1DBD6;
      position: relative;
      top: -10px;
      
    }
  }
}
  

.blue{
    background-color: #4F849F;

}
.green{
  background-color: #509B8D;
}

.dx-item-content{
  .dx-list-item-content{
    padding-left: 0;
    padding-bottom: 0;
  }
} 
