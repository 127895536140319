.dx-swatch-additional .dx-scrollable-content {
    -webkit-transform: none;
  }
  .dx-swatch-additional .dx-rtl .dx-scrollable-scroll {
    padding-left: 0;
    padding-right: 2px;
  }
  .dx-swatch-additional .dx-scrollable-scroll-content {
    width: 100%;
    height: 100%;
    background-color: rgba(112,112,133,.7);
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
  }
  .dx-swatch-additional .dx-scrollbar-hoverable {
    background-color: transparent;
  }
  .dx-swatch-additional .dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible {
    opacity: 1;
  }
  .dx-swatch-additional .dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
    background-color: rgba(0,0,0,0);
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
  }
  .dx-swatch-additional .dx-scrollbar-vertical .dx-scrollable-scroll {
    float: right;
    width: 6px;
  }
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable {
    width: 10px;
    -webkit-transition: width .2s linear .15s,background-color .2s linear .15s;
    transition: width .2s linear .15s,background-color .2s linear .15s;
  }
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll {
    -webkit-transition: background-color .5s linear 1s,width .2s linear 150ms;
    transition: background-color .5s linear 1s,width .2s linear 150ms;
  }
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll .dx-scrollable-scroll-content {
    -webkit-transition: background-color .15s linear .15s,-webkit-box-shadow .15s linear .15s;
    transition: box-shadow .15s linear .15s,background-color .15s linear .15s,-webkit-box-shadow .15s linear .15s;
  }
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible {
    -webkit-transition: background-color .5s linear 1s,width .2s linear .15s;
    transition: background-color .5s linear 1s,width .2s linear .15s;
  }
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
    -webkit-transition: background-color .5s linear 1s,-webkit-box-shadow .5s linear 1s;
    transition: box-shadow .5s linear 1s,background-color .5s linear 1s,-webkit-box-shadow .5s linear 1s;
  }
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover,
  .dx-swatch-additional .dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
    width: 13px;
  }