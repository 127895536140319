@import "../../../themes/generated/variables.base.scss";

.redondo{
  border-radius: 25px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.area-combos{
  display:-webkit-flex;
  display:flex;
}
.area-combos>div{
  width: 35%;
  padding: 10px;
}

