body {
  overflow:hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swal2-container{
  z-index: 2000 !important;
}

.dx-progressbar-status{
  height: 0 !important;
  padding:0 !important;
}

.dx-progressbar-container{
  height: 6px !important;
}

.dx-progressbar-animating-container{
  height: 6px !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#footerContent{
  width: 100%;
  margin-left: 0;
  margin-right:0;
  height: 120px;
  /*position: absolute;
  bottom: 0*/
}

/*Para lograr ver todos los items del menu*/
.dx-drawer-wrapper{
  height: 93vh !important;
}

.dx-toolbar .dx-toolbar-items-container{
  height: 7vh !important;
}

